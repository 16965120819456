<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
).px-4
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'TableEducation',
  data () {
    return {
      headers: [
        { value: 'sailor.id', text: this.$t('sailorId') },
        { value: 'number_document', text: this.$t('number') },
        { value: 'serial', text: this.$t('serial') },
        { value: 'registry_number', text: this.$t('registrationNumber') },
        { value: 'sailorFullName', text: this.$t('fullName') },
        { value: 'type_document', text: this.$t('typeDoc') },
        { value: 'name_nz.name_ukr', text: this.$t('nameInstitution') },
        { value: 'qualification', text: this.$t('qualification') },
        { value: 'date_start', text: this.$t('dateIssue') },
        { value: 'experied_date', text: this.$t('dateTermination') },
        { value: 'status_document', text: this.$t('status') },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          to: (item) => ({ name: 'education-documents-info', params: { id: item.sailor.id, documentID: item.id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.listEducation,
      isLoading: state => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getListEducationReport']),
    async getData (params) {
      this.getListEducationReport({ ...this.$route.params, ...params })
    }

  }
}
</script>
